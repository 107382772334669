<template>
  <div class="message" style="width: 80%; margin-left: -40%; top: 5%">
    <div class="grid-x grid-padding-x pt-4">
      <div class="cell small-12">
        <h4 class="pb-2">
          Activity Log for {{ activityLogs[0].job_number.job_number }}
          {{ activityLogs[0].job_number.description }}
        </h4>
        <ul class="pl-4">
          <li :key="index" v-for="(log, index) in activityLogs">
            Action required: {{ log.action_required }}
            Action by: {{ log.action_by }}
            Status: {{ log.status }}
            Date: {{ log.created | tinyDate }}
          </li>
        </ul>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-4">
      <div class="cell small-10 medium-3">
        <button
          @click="close()"
          class="button">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'ActivityLog',
  emits: [
    'close',
  ],
  props: [
    'jobNumberId',
  ],
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  data() {
    return {
      activityLogs: [],
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    getActivityLogs() {
      axios.get(`/activityLogs/getByJobNumber/${this.jobNumberId}.json?token=${this.token}`)
        .then((response) => {
          this.activityLogs = response.data.activityLogs;
        });
    },
  },
  mounted() {
    this.getActivityLogs();
  },
};
</script>
