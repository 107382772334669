<template>
  <div class="grid-container fluid">
    <div class="grid-x grid-padding-x pt-4">
      <div class="cell small-12 medium-10">
        <h1>Jobs</h1>
      </div>
      <div class="cell small-6 medium-2">
        <router-link
          to="/job-numbers/create">
          <button class="button expanded">
            Add Job
          </button>
        </router-link>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-2">
      <div class="cell small-12 medium-2">
        <label>Client</label>
        <select v-model="searchClient">
          <option
            v-for="(client, index) in clientList"
            :key="`c_${index}`"
            :value="client.id">{{ client.name }}</option>
        </select>
      </div>
      <div class="cell small-12 medium-2">
        <label>Brand</label>
        <select v-model="searchBrand">
          <option
            v-for="(brand, index) in brandList"
            :key="`br_${index}`"
            :value="brand.id">{{ brand.name }}</option>
        </select>
      </div>
      <div class="cell small-12 medium-1">
        <label>Status</label>
        <select v-model="searchActivityStatus">
          <option value="0">All</option>
          <option value="1">With Agency</option>
          <option value="2">With Client</option>
        </select>
      </div>
      <div class="cell small-12 medium-2">
        <label>Job Number</label>
        <input type="text" v-model="searchJobNumber">
      </div>
      <div class="cell small-12 medium-2">
        <label>Veeva Code</label>
        <input type="text" v-model="searchVeevaCode">
      </div>
      <div class="cell small-12 medium-1">
        <label>Archived</label>
        <select v-model="searchArchived">
          <option value="false">Current</option>
          <option value="true">Archived</option>
        </select>
      </div>
      <div class="cell small-12 medium-2">
        <!--
          <label>Client or Brand</label>
          <input type="text" v-model="searchClientBrand">
        -->
        <label>Requires invoice?</label>
        <select v-model="searchRequiresInvoice">
          <option value="0">All</option>
          <option value="1">Yes</option>
        </select>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12">
        <table class="position-relative">
          <thead>
          <tr>
            <th style="min-width:230px;" class="sticky-header">Actions</th>
            <th style="min-width:200px;" class="sticky-header">Job Number</th>
            <th style="min-width:200px;" class="sticky-header">Veeva Code</th>
            <th style="min-width:840px;" class="sticky-header">Job Description</th>
            <th style="min-width:140px;" class="sticky-header">Activity Status</th>
            <th style="min-width:140px;" class="sticky-header">Last Activity</th>
            <th style="min-width:600px;" class="sticky-header">Action Required</th>
            <th style="min-width:140px;" class="sticky-header text-red">Action By</th>
            <th style="min-width:68px;" class="sticky-header">&nbsp;</th>
            <th style="min-width:200px;" class="sticky-header">
              Project Deadline
              <span
                @click="filter('project_deadline', 'DESC')"
                title="Desc"
                class="material-symbols-outlined icon-yellow icon-link"
                style="position: relative; top: 6px;">
                  arrow_downward
                </span>
              <span
                @click="filter('project_deadline', 'ASC')"
                title="Asc  "
                class="material-symbols-outlined icon-yellow icon-link"
                style="position: relative; top: 6px;">
                  arrow_upward
                </span>
            </th>
            <th style="min-width:120px" class="sticky-header">Dice Primary</th>
            <th style="min-width:140px" class="sticky-header">Client Contact</th>
            <th style="min-width:40px" class="sticky-header">Est</th>
            <th style="min-width:90px" class="sticky-header">Status</th>
            <th style="min-width:70px" class="sticky-header">PO No</th>
            <th style="min-width:120px" class="sticky-header">Value</th>
            <th style="min-width:120px" class="sticky-header">Hours</th>
            <th style="min-width:120px" class="sticky-header">% Billed</th>
            <th style="min-width:40px" class="sticky-header">&nbsp;</th>
            <th style="min-width:600px" class="sticky-header">Notes</th>
            <th style="min-width:100px" class="sticky-header">&nbsp;</th>
          </tr>
          </thead>
          <tbody
            v-for="(brand, bindex) in brands"
            :key="`b_${bindex}`">
            <tr>
              <th class="dark-header">Client: {{ brand.client_name }}</th>
              <th class="dark-header">Brand: {{ brand.brand_name}}</th>
              <th colspan="19" class="dark-header">&nbsp;</th>
            </tr>
            <tr v-for="(jobNumber, index) in brand.jobNumbers" :key="`j_${index}`">
              <td>
                <span v-if="jobNumber.estimates[0]">
                  <router-link
                    :to="`/estimates/view/${jobNumber.estimates[0].id}`">
                    <span
                      title="View estimate"
                      class="material-symbols-outlined icon-purple icon-link"
                    >Eyeglasses</span>
                  </router-link>
                </span>
                &nbsp;
                <span v-if="jobNumber.estimates[0] && jobNumber.status > 2">
                  <span v-if="jobNumber.estimates[0].percentage_billed < 100">
                    <span
                      @click="selectedEstimate = jobNumber.estimates[0].id"
                      title="Create invoice"
                      class="material-symbols-outlined icon-green icon-link"
                      v-if="jobNumber.requiresInvoice === false">Receipt</span>
                    <span
                      @click="selectedEstimate = jobNumber.estimates[0].id"
                      title="Create invoice"
                      class="material-symbols-outlined icon-red icon-link"
                      v-else>Receipt</span>
                  </span>
                  <span
                    title="Job is 100% invoiced"
                    class="material-symbols-outlined icon-grey icon-link"
                    v-else>Receipt</span>
                </span>
                &nbsp;
                <router-link
                  v-if="jobNumber.estimates[0] && jobNumber.status < 3"
                  :to="`/estimates/revise/${jobNumber.estimates[0].id}`">
                  <span
                    title="Revise Estimate"
                    class="material-symbols-outlined icon-yellow   icon-link"
                  >Upgrade</span>
                </router-link>
                <router-link v-else :to="`/estimates/create/${jobNumber.id}`">
                  <span
                    title="Add Estimate"
                    class="material-symbols-outlined icon-green icon-link"
                  >Add</span>
                </router-link>
                &nbsp;
                <router-link
                  v-if="!jobNumber.child_letter"
                  :to="`/job-numbers/create-child/${jobNumber.id}`">
                  <span
                    title="Add Child"
                    class="material-symbols-outlined icon-green icon-link"
                  >escalator_warning</span>
                </router-link>
                &nbsp;
                <span
                  v-if="!jobNumber.child_letter"
                  @click="copyJob(bindex, index)"
                  title="Copy"
                  class="material-symbols-outlined icon-yellow icon-link"
                >content_copy</span>
                <span
                  v-if="jobNumber.child_letter && jobNumber.status === 1"
                  @click="setChildLive(bindex, index)"
                  title="Set Child Live"
                  class="material-symbols-outlined icon-yellow icon-link">
                  star
                </span>
              </td>
              <td>
                <span v-if="jobNumber.child_letter" class="text-italic child-job-indicator">
                  {{ jobNumber.job_number }}
                  <span title="Child Job" class="material-symbols-outlined icon-small">
                    escalator_warning
                  </span>
                </span>
                <span v-else>
                  {{ jobNumber.job_number }}
                </span>
              </td>
              <td>
                <span v-if="veevaInEdit !== jobNumber.id">{{ jobNumber.veeva_code }}</span>
                <input
                  style="width: 75%"
                  class="mb-0"
                  v-if="veevaInEdit === jobNumber.id"
                  v-model="jobNumber.veeva_code">
                &nbsp;
                <span
                  v-if="veevaInEdit !== jobNumber.id"
                  @click="veevaInEdit = jobNumber.id"
                  title="Update Veeva Code"
                  class="material-symbols-outlined icon-yellow icon-link"
                  style="position: relative; top: 5px;"
                >edit</span>
                <span
                  v-if="veevaInEdit === jobNumber.id"
                  @click="updateVeevaCode(bindex, index)"
                  title="Save changes"
                  class="material-symbols-outlined icon-green icon-link"
                  style="position: relative; top: 5px;"
                >Save</span>
              </td>
              <td>
                <span v-if="descriptionInEdit !== jobNumber.id">{{ jobNumber.description }}</span>
                <input
                  class="mb-0"
                  style="width: 75%"
                  v-if="descriptionInEdit === jobNumber.id"
                  v-model="jobNumber.description">
                &nbsp;
                <span
                  v-if="descriptionInEdit !== jobNumber.id"
                  @click="descriptionInEdit = jobNumber.id"
                  title="Update Description"
                  class="material-symbols-outlined icon-yellow icon-link"
                  style="position: relative; top: 5px;"
                >edit</span>
                <span
                  v-if="descriptionInEdit === jobNumber.id"
                  @click="updateDescription(bindex, index)"
                  title="Save changes"
                  class="material-symbols-outlined icon-green icon-link"
                  style="position: relative; top: 5px;"
                >Save</span>
              </td>
              <td>
                <div v-if="jobNumber.activity_logs[0]">
                  <select
                    v-if="jobNumber.id === lineInEdit"
                    class="mb-0"
                    style="height: 28px"
                    v-model="jobStatus">
                    <option>With client</option>
                    <option>With agency</option>
                  </select>
                  <span v-else>
                    <span v-if="jobNumber.activity_status === 1">With Agency</span>
                    <span v-else>With Client</span>
                  </span>
                </div>
              </td>
              <td>
                <div v-if="jobNumber.activity_logs[0]">
                  {{ jobNumber.activity_logs[0].created | tinyDate }}
                </div>
              </td>
              <td>
                <div v-if="jobNumber.activity_logs[0]">
                  <input
                    v-if="jobNumber.id === lineInEdit"
                    class="mb-0"
                    style="height: 28px"
                    type="text"
                    v-model="jobAction">
                  <span v-else>
                    {{ jobNumber.activity_logs[0].action_required }}
                  </span>
                </div>
              </td>
              <td>
                <div v-if="jobNumber.activity_logs[0]">
                  <input
                    v-if="jobNumber.id === lineInEdit"
                    class="mb-0"
                    style="height: 28px"
                    type="text"
                    v-model="jobActionBy">
                  <span v-else>
                    {{ jobNumber.activity_logs[0].action_by }}
                  </span>
                </div>
              </td>
              <td>
                <span
                  v-if="lineInEdit !== jobNumber.id"
                  @click="putLineInEdit(brand.id, jobNumber.id)"
                  title="Update job"
                  class="material-symbols-outlined icon-yellow icon-link"
                >edit</span>
                <span
                  v-if="lineInEdit === jobNumber.id"
                  @click="saveUpdate(jobNumber.id)"
                  title="Save changes"
                  class="material-symbols-outlined icon-green icon-link"
                >Save</span>
                <span
                  @click="viewActivityLog = jobNumber.id"
                  title="View Activity Log"
                  class="material-symbols-outlined icon-green icon-link"
                >visibility</span>
              </td>
              <td>
                <select
                  @change="updateProjectDeadline(bindex, index)"
                  class="mb-0"
                  v-model="jobNumber.project_deadline">
                  <option
                    v-for="(pd, index) in projectDeadlines"
                    :key="`pd_${index}`"
                    :value="pd.key"
                  >
                    {{ pd.value }}
                  </option>
                </select>
              </td>
              <td>
                <div v-if="jobNumber.estimates[0]">
                  {{ jobNumber.estimates[0].user.initials }}
                </div>
              </td>
              <td>
                <div v-if="jobNumber.estimates[0]">
                  <select
                    @change="updateClientContact(bindex, index)"
                    class="mb-0"
                    v-model="jobNumber.estimates[0].contact_id">
                    <option
                      v-for="(contact, index) in clientContacts"
                      :key="`cc_${index}`"
                      :value="contact.id"
                    >
                      {{ contact.name }}
                    </option>
                  </select>
                </div>
              </td>
              <td>
                <span
                  v-if="jobNumber.estimates[0]"
                  class="material-symbols-outlined icon-green">
                  done
                </span>
                <span
                  v-else
                  class="material-symbols-outlined icon-red">
                  close
                </span>
              </td>
              <td>
                <div v-if="jobNumber.estimates[0]">
                  <span
                    v-if="jobNumber.estimates[0].converted === false ||
                      jobNumber.estimates[0].converted === 0">
                    Draft
                  </span>
                  <span
                    v-if="jobNumber.estimates[0].converted === true &&
                    jobNumber.estimates[0].archived === false">
                    Live</span>
                </div>
                <div v-else>
                  New
                </div>
              </td>
              <td>
                <div v-if="jobNumber.estimates[0]">
                  {{ jobNumber.estimates[0].po_number }}
                </div>
              </td>
              <td>
                <div v-if="jobNumber.estimates[0]">
                  {{ jobNumber.estimates[0].totalValue | currency }}
                </div>
              </td>
              <td style="position: relative;">
                {{ jobNumber.totalTime }}
                <span
                  @click="showTimes = `${bindex}${index}`"
                  title="View Time Breakdown"
                  class="material-symbols-outlined icon-green icon-link"
                  style="font-size: 20px; position: relative; top: 4px;">
                  acute
                </span>
                <div
                  class="time-breakdown"
                  v-if="showTimes === `${bindex}${index}`">
                  Time breakdown:
                  <span
                    @click="showTimes = ''"
                    class="material-symbols-outlined icon-red icon-link float-right">
                    close
                  </span><br />
                  <span :key="dtindex" v-for="(domain, dtindex) in jobNumber.domainTimes">
                    {{ domain.name }} {{ domain.totalTime }}<br />
                  </span>
                </div>
              </td>
              <td>
                <div v-if="jobNumber.estimates[0]">
                  {{ jobNumber.estimates[0].percentage_billed }}
                </div>
              </td>
              <td>&nbsp;</td>
              <td>
                <span
                  v-for="(note, nindex) in jobNumber.job_number_notes"
                  :key="`jn_${nindex}`">
                  <p>
                    {{ note.created | tinyDate }} {{ note.note }}
                    <span
                      @click="deleteNote(bindex, index, nindex)"
                      title="Delete Note"
                      class="material-symbols-outlined icon-red icon-link"
                      style="font-size:14px;">
                    delete</span>
                  </p>
                </span>
              </td>
              <td>
                <span
                  @click="addNoteId = jobNumber.id"
                  title="Add Note"
                  class="material-symbols-outlined icon-green icon-link"
                >post_add</span>
                &nbsp;
                &nbsp;
                <span v-if="jobNumber.estimates[0]">
                  <span
                    @click="confirmArchive(bindex, index)"
                    title="Archive"
                    class="material-symbols-outlined icon-red icon-link"
                    v-if="jobNumber.estimates[0].percentage_billed === 100
                    && jobNumber.archived === false">
                    archive
                  </span>
                  <span
                    @click="confirmUnArchive(bindex, index)"
                    title="Un-Archive"
                    class="material-symbols-outlined icon-green icon-link"
                    v-if="jobNumber.archived === true">
                    unarchive
                  </span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <ActivityLog
      @close="viewActivityLog = -1"
      :jobNumberId="viewActivityLog"
      v-if="viewActivityLog > -1" />
    <AddNote v-if="addNoteId > 0" :jobNumber="addNoteId" @noteAdded="addNote" />
    <RaiseInvoice
      v-if="selectedEstimate > 0"
      :estimateId="selectedEstimate"
      @cancel="selectedEstimate = 0"
      @invoiceRaised="goToNewInvoice" />
    <div class="dialogue" v-if="dialog">
      <p class="pb-4">Are you sure you wish to archive this job?</p>
      <p>
        <button @click="archiveJob()" class="button">
          Confirm
        </button>
        &nbsp;
        <button @click="dialog = false" class="button">
          Cancel
        </button>
      </p>
    </div>
    <div class="dialogue" v-if="unArchiveBindex >= 0">
      <p class="pb-4">Are you sure you wish to un-archive this job?</p>
      <p>
        <button @click="unArchiveJob()" class="button">
          Confirm
        </button>
        &nbsp;
        <button @click="dialog = false" class="button">
          Cancel
        </button>
      </p>
    </div>
    <div class="dialogue" v-if="copyIndex > -1">
      <p class="pb-4">Are you sure you wish to copy this job?</p>
      <p>
        <button @click="completeCopyJob()" class="button">
          Confirm
        </button>
        &nbsp;
        <button @click="copyIndex = -1" class="button">
          Cancel
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import ActivityLog from './components/ActivityLog.vue';
import AddNote from './components/AddNote.vue';
import RaiseInvoice from './components/RaiseInvoice.vue';
import axios from '../../axios';

export default {
  name: 'JobNumbersIndex',
  components: {
    ActivityLog,
    AddNote,
    RaiseInvoice,
  },
  data() {
    return {
      accountHandlerList: [],
      addNoteId: 0,
      archiveBindex: -1,
      archiveIndex: -1,
      unArchiveBindex: -1,
      unArchiveIndex: -1,
      brandList: [],
      brands: [],
      clientContacts: [],
      clientList: [],
      client: {
        id: 0,
        brands: [],
      },
      copyBindex: -1,
      copyIndex: -1,
      dialog: false,
      descriptionInEdit: -1,
      failed: false,
      firstLoad: true,
      jobAction: '',
      jobActionBy: '',
      jobStatus: '',
      lineInEdit: -1,
      lineInEditBrand: -1,
      page: 0,
      projectDeadlines: [],
      searchRequiresInvoice: 0,
      searchActivityStatus: 0,
      searchArchived: false,
      searchBrand: 999,
      searchClient: 0,
      searchClientBrand: '',
      searchJobNumber: '',
      searchVeevaCode: '',
      selectedEstimate: {
        client_id: 0,
      },
      showTimes: '',
      veevaInEdit: -1,
      viewActivityLog: -1,
    };
  },
  computed: {
    estimates() {
      return this.$store.state.estimates.estimates;
    },
    pageCount() {
      return this.$store.state.estimates.estimateCount;
    },
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  watch: {
    searchActivityStatus() {
      this.setSearchParams();
      this.searchJobs();
    },
    searchArchived() {
      this.setSearchParams();
      this.searchJobs();
    },
    searchBrand() {
      this.setSearchParams();
      this.searchJobs();
    },
    searchClient(value) {
      this.brands = [];
      const client = this.clientList.filter((clientVal) => clientVal.id === value)[0];
      if (this.firstLoad === false) {
        this.searchBrand = 999;
      }
      this.firstLoad = false;
      if (client) {
        // eslint-disable-next-line
        const brands = client.brands;
        if (brands.some((e) => e.name === 'All')) {
          console.log('Found');
        } else {
          brands.unshift({
            id: 999,
            clientId: client.id,
            name: 'All',
          });
        }
        this.brandList = brands;
        this.setSearchParams();
        this.searchJobs();
      }
    },
    searchJobNumber() {
      this.setSearchParams();
      this.searchJobs();
    },
    searchRequiresInvoice() {
      this.setSearchParams();
      this.searchJobs();
    },
    searchVeevaCode() {
      this.setSearchParams();
      this.searchJobs();
    },
    searchClientBrand() {
      this.setSearchParams();
      this.searchJobs();
    },
  },
  methods: {
    calculatePercentages() {
      axios.get(`/estimates/calculatePercentageBilled.json?token=${this.token}`);
    },
    setSearchParams() {
      const searchValues = {};
      searchValues.activityStatus = this.searchActivityStatus;
      searchValues.archived = this.searchArchived;
      searchValues.brand = this.searchBrand;
      searchValues.client = this.searchClient;
      searchValues.jobNumber = this.searchJobNumber;
      searchValues.veevaCode = this.searchVeevaCode;
      searchValues.clientBrand = this.searchClientBrand;
      searchValues.requiresInvoice = this.searchRequiresInvoice;
      this.$store.commit('setSearch', searchValues);
    },
    updateVeevaCode(bindex, index) {
      if (this.brands[bindex].jobNumbers[index].veeva_code.length > 3) {
        const postData = {};
        postData.jobNumber = this.brands[bindex].jobNumbers[index];
        axios.post(`/jobNumbers/updateVeevaCode.json?token=${this.token}`, postData)
          .then(() => {
            this.veevaInEdit = -1;
          });
      }
    },
    updateDescription(bindex, index) {
      if (this.brands[bindex].jobNumbers[index].description.length > 5) {
        const postData = {};
        postData.jobNumber = this.brands[bindex].jobNumbers[index];
        axios.post(`/jobNumbers/updateDescription.json?token=${this.token}`, postData)
          .then(() => {
            this.descriptionInEdit = -1;
          });
      }
    },
    updateProjectDeadline(bindex, index) {
      const postData = {};
      postData.jobNumber = this.brands[bindex].jobNumbers[index];
      axios.post(`/jobNumbers/updateProjectDeadline.json?token=${this.token}`, postData);
    },
    updateClientContact(bindex, index) {
      const postData = {};
      // eslint-disable-next-line
      postData.estimate = this.brands[bindex].jobNumbers[index].estimates[0];
      axios.post(`/estimates/updateClientContact.json?token=${this.token}`, postData);
    },
    updateSearchParams() {
      this.searchArchived = this.$store.state.searchArchived;
      this.searchActivityStatus = this.$store.state.searchActivityStatus;
      this.searchBrand = this.$store.state.searchBrand;
      this.searchClient = this.$store.state.searchClient;
      this.searchClientBrand = this.$store.state.searchClientBrand;
      this.searchJobNumber = this.$store.state.searchJobNumber;
      this.searchVeevaCode = this.$store.state.searchVeevaCode;
      const clientId = this.searchClient;
      let client = {};
      for (let i = 0; i < this.clientList.length; i += 1) {
        if (this.clientList[i].id === clientId) {
          client = this.clientList[i];
        }
      }
      this.brandList = client.brands;
    },
    confirmArchive(bindex, index) {
      this.archiveBindex = bindex;
      this.archiveIndex = index;
      this.dialog = true;
    },
    confirmUnArchive(bindex, index) {
      this.unArchiveBindex = bindex;
      this.unArchiveIndex = index;
    },
    archiveJob() {
      const jobNumber = this.brands[this.archiveBindex].jobNumbers[this.archiveIndex];
      axios.get(`/jobNumbers/archive/${jobNumber.id}.json?token=${this.token}`)
        .then(() => {
          this.brands[this.archiveBindex].jobNumbers.splice(this.archiveIndex, 1);
          this.dialog = false;
        });
    },
    unArchiveJob() {
      const jobNumber = this.brands[this.unArchiveBindex].jobNumbers[this.unArchiveIndex];
      axios.get(`/jobNumbers/unArchive/${jobNumber.id}.json?token=${this.token}`)
        .then(() => {
          this.brands[this.unArchiveBindex].jobNumbers.splice(this.unArchiveIndex, 1);
          this.unArchiveBindex = -1;
          this.unArchiveIndex = -1;
        });
    },
    copyJob(bindex, index) {
      this.copyBindex = bindex;
      this.copyIndex = index;
    },
    setChildLive(bindex, index) {
      const postData = {};
      postData.jobNumber = this.brands[bindex].jobNumbers[index];
      axios.post(`/jobNumbers/setChildLive.json?token=${this.token}`, postData)
        .then((response) => {
          if (response.data.result === true) {
            this.brands[bindex].jobNumbers[index].status = 3;
          }
        });
    },
    completeCopyJob() {
      const postData = {};
      postData.jobNumber = this.brands[this.copyBindex].jobNumbers[this.copyIndex];
      postData.userid = this.userid;
      axios.post(`/jobNumbers/copy.json?token=${this.token}`, postData)
        .then((response) => {
          const newJob = response.data.jobNumber;
          this.brands[this.copyBindex].jobNumbers.push(newJob);
        });
    },
    addNote(value) {
      this.addNoteId = 0;
      for (let i = 0; this.brands.length > i; i += 1) {
        for (let x = 0; this.brands[i].jobNumbers.length > x; x += 1) {
          if (this.brands[i].jobNumbers[x].id === value.job_number_id) {
            this.brands[i].jobNumbers[x].job_number_notes.push(value);
          }
        }
      }
    },
    deleteNote(bindex, index, nindex) {
      const note = this.brands[bindex].jobNumbers[index].job_number_notes[nindex];
      axios.get(`/jobNumberNotes/delete/${note.id}.json?token=${this.token}`)
        .then(() => {
          this.brands[bindex].jobNumbers[index].job_number_notes.splice(nindex, 1);
        });
    },
    goToNewInvoice(value) {
      const invoiceId = value.invoice.id;
      this.$router.push(`/invoices/view/${invoiceId}`);
    },
    putLineInEdit(brandId, jobNumberId) {
      const brand = this.brands.filter((br) => br.id === brandId)[0];
      let jobNumber = {};
      for (let i = 0; brand.jobNumbers.length > i; i += 1) {
        if (brand.jobNumbers[i].id === jobNumberId) {
          jobNumber = brand.jobNumbers[i];
        }
      }
      this.lineInEditBrand = brandId;
      this.lineInEdit = jobNumber.id;
      this.jobAction = jobNumber.activity_logs[0].action_required;
      this.jobActionBy = jobNumber.activity_logs[0].action_by;
      this.jobStatus = jobNumber.activity_logs[0].status;
    },
    saveUpdate(jobNumberId) {
      this.lineInEdit = -1;
      const postData = {};
      postData.jobNumberId = jobNumberId;
      postData.action = this.jobAction;
      postData.actionBy = this.jobActionBy;
      postData.status = this.jobStatus;
      postData.userid = this.userid;
      axios.post(`/activityLogs/create.json?token=${this.token}`, postData)
        .then((response) => {
          const brand = this.brands.filter((br) => br.id === this.lineInEditBrand)[0];
          const localJobId = response.data.activityLog.job_number_id;
          for (let x = 0; brand.jobNumbers.length > x; x += 1) {
            if (brand.jobNumbers[x].id === localJobId) {
              brand.jobNumbers[x].activity_logs[0].action_by = response.data.activityLog.action_by;
              // eslint-disable-next-line
              brand.jobNumbers[x].activity_logs[0].action_required = response.data.activityLog.action_required;
              brand.jobNumbers[x].activity_logs[0].status = response.data.activityLog.status;
            }
          }
        });
    },
    searchJobs() {
      const postData = { search: {} };
      postData.search.accountHandler = this.searchAccountHandler;
      postData.search.activityStatus = this.searchActivityStatus;
      postData.search.archived = this.searchArchived;
      postData.search.brand = this.searchBrand;
      postData.search.client = this.searchClient;
      postData.search.clientBrand = this.searchClientBrand;
      postData.search.jobNumber = this.searchJobNumber;
      postData.search.requiresInvoice = this.searchRequiresInvoice;
      postData.search.veevaCode = this.searchVeevaCode;
      axios.post(`/jobNumbers/getAll/${this.page}.json?token=${this.token}`, postData)
        .then((response) => {
          this.brands = response.data.brands;
          this.clientContacts = response.data.clientContacts;
        })
        .catch(() => {
          this.failed = true;
        });
    },
    filter(filter, direction) {
      const postData = { search: {} };
      postData.search.accountHandler = this.searchAccountHandler;
      postData.search.activityStatus = this.searchActivityStatus;
      postData.search.archived = this.searchArchived;
      postData.search.brand = this.searchBrand;
      postData.search.client = this.searchClient;
      postData.search.clientBrand = this.searchClientBrand;
      postData.search.jobNumber = this.searchJobNumber;
      postData.search.veevaCode = this.searchVeevaCode;
      axios.post(`/jobNumbers/getAll/${this.page}.json?token=${this.token}&filter=${filter}&direction=${direction}`, postData)
        .then((response) => {
          this.brands = response.data.brands;
          this.clientContacts = response.data.clientContacts;
        })
        .catch(() => {
          this.failed = true;
        });
    },
    getJobs() {
      axios.get(`/jobNumbers/getAll/${this.page}/${this.showArchived}.json?token=${this.token}`)
        .then((response) => {
          this.brands = response.data.brands;
          this.clientContacts = response.data.clientContacts;
        })
        .catch(() => {
          this.failed = true;
        });
    },
    getSearchLists() {
      axios.get(`/searches/getJobSearchList/${this.page}.json?token=${this.token}`)
        .then((response) => {
          this.accountHandlerList = response.data.accountHandlers;
          this.clientList = response.data.clients;
          if (this.$route.params.clientId) {
            this.searchClient = parseInt(this.$route.params.clientId, 10);
          }
          this.updateSearchParams();
        })
        .catch(() => {
          this.failed = true;
        });
    },
    getProjectDeadlines() {
      axios.get(`/utilityTools/getProjectDeadlines.json?token=${this.token}`)
        .then((response) => {
          this.projectDeadlines = response.data.projectDeadlines;
        })
        .catch(() => {
          this.failed = true;
        });
    },
  },
  filters: {
    statusText(value) {
      if (!value) return '';
      let text = '';
      if (value === 1) { text = 'New'; }
      if (value === 2) { text = 'Estimate'; }
      if (value === 3) { text = 'Converted'; }
      if (value === 4) { text = 'Complete'; }
      if (value === 5) { text = 'Paid'; }
      if (value === 6) { text = 'Closed'; }
      return text;
    },
  },
  mounted() {
    setTimeout(() => {
      this.getProjectDeadlines();
      this.getSearchLists();
    }, 100);
  },
};
</script>
