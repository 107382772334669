<template>
  <div class="dialogue">
    <label>Add Note</label>
    <textarea rows="5" v-model="note"></textarea>
    <button @click="saveNote" class="button">Save</button>
  </div>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'Add note',
  props: [
    'jobNumber',
  ],
  data() {
    return {
      note: '',
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  methods: {
    saveNote() {
      const postData = {};
      postData.note = this.note;
      postData.jobNumber = this.jobNumber;
      postData.userid = this.userid;
      axios.post(`/jobNumberNotes/create.json?token=${this.token}`, postData)
        .then((response) => {
          const newNote = response.data.note;
          this.$emit('noteAdded', newNote);
        })
        .catch(() => {
          this.failed = true;
        });
    },
  },
};
</script>
